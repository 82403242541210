import CaptchaV2 from "./components/captcha-v2/CaptchaV2";
import { Navigate } from "react-router-dom";

const AppRoutes = [
  {
    index: true,
    element: <Navigate to="/v2" />,
  },
  {
    path: "/v2",
    element: <CaptchaV2 />,
  },
];

export default AppRoutes;
