import ReCAPTCHA from "react-google-recaptcha";
import styles from "./CaptchaV2.module.scss";
import { useRef } from "react";

const CaptchaV2 = () => {
  const txtAreaRef = useRef();

  const onSubmit = (event) => {
    event.preventDefault();
    alert("submit");
    console.log(event);
    debugger;
  };

  const onChange = (event) => {
    txtAreaRef.current.value = event;
  };

  const asyncScriptOnLoad = () => {
    // alert("grecaptcha is ready!");
  };

  return (
    <div className={styles["g-recaptcha"]}>
      <form onSubmit={onSubmit}>
        <ReCAPTCHA
          sitekey="6LeQ0-4nAAAAAEq5kE7WcLrhlsWDcBHDkZNn2uVy"
          onChange={onChange}
          asyncScriptOnLoad={asyncScriptOnLoad}
        />

        <textarea name="response_txt" ref={txtAreaRef}></textarea>
      </form>
    </div>
  );
};

export default CaptchaV2;
